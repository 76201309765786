<template>
  <div class="expansion-panel">
    <div
      tabindex="0"
      role="button"
      :aria-expanded="isExpanded"
      class="expansion-panel__header d-flex flex-wrap justify-content-between"
      @click.prevent="toggle"
      @keydown="toggle"
    >
      <div :class="['expansion-panel__title fw-bold', { active: isExpanded }]">
        <slot name="title">
          {{ title }}
        </slot>
      </div>
      <div
        :class="[
          'expansion-panel__arrow-wrapper d-flex align-items-center justify-content-end',
          { active: isExpanded },
        ]"
      >
        <div class="arrow-icon__wrapper text-center">
          <ArrowIcon class="arrow-icon" />
        </div>
      </div>
    </div>
    <div v-show="isExpanded && isLoading" class="pb-32">
      <div class="text-center">
        <BaseSpinner />
      </div>
    </div>
    <transition
      name="fade"
      @before-enter="beforeEnter"
      @enter="enter"
      @before-leave="beforeLeave"
      @leave="leave"
    >
      <div
        v-show="isExpanded && !isLoading"
        class="expansion-panel__body overflow-hidden pb-24 w-100"
      >
        <slot name="body"></slot>
      </div>
    </transition>
  </div>
</template>

<script>
import ArrowIcon from '~/assets/icons/chevron-down.svg'

export default {
  components: {
    ArrowIcon,
  },
  props: {
    onToggle: {
      type: Function,
      default: () => {},
    },
    title: {
      type: String,
      default: '',
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
    expanded: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isExpanded: this.expanded,
    }
  },
  methods: {
    toggle() {
      this.isExpanded = !this.isExpanded
      this.onToggle()
    },
    beforeEnter(el) {
      el.style.maxHeight = 0
      el.style.opacity = 0
      el.style.transition = 'all 0.4s ease-out'
    },
    enter(el) {
      el.style.maxHeight = el.scrollHeight + 'px'
      el.style.opacity = 1
    },
    beforeLeave(el) {
      el.style.transition = 'all 0.3s ease-in'
      el.style.maxHeight = el.scrollHeight + 'px'
    },
    leave(el) {
      el.style.maxHeight = 0
      el.style.opacity = 0
      el.style.padding = 0
      el.style.margin = 0
    },
  },
}
</script>

<style lang="scss" scoped>
.expansion-panel {
  border-top: 1px solid #e9e5e0;

  &:focus {
    outline: 0;
  }
}

.expansion-panel__header {
  cursor: pointer;
  outline: none;
}

.expansion-panel__title {
  font-size: 18px;
  padding: 24px 0;
  max-width: 80%;
}

.arrow-icon__wrapper {
  background: #fbfaf9;
  border-radius: 50px;
  height: 40px;
  line-height: 40px;
  width: 40px;
}

.arrow-icon {
  fill: $grey-700;
  transform: rotate(0deg);
  transition-duration: 0.3s;
}

.expansion-panel__arrow-wrapper {
  &:hover {
    cursor: pointer;
  }

  &.active {
    .arrow-icon {
      transform: rotate(180deg);
    }
  }
}
</style>
